<template lang="pug">
ListPageLayout(title="Список организаций")
  template(#filters)
    v-col(cols="12" sm="6" md="4")
      v-text-field(
        label="Поиск организаций"
        placeholder="Поиск по названию/телефону/адресу"
        append-icon="fa-search"
        :value="listSearch"
        @input="querySearchList"
        outlined
        hide-details="auto"
        :disabled="listIsLoading"
        :clearable="listSearch != null"
        @click:clear="querySearchList('')"
        ref="search"
        dense
      )

  template(#actions)
    export-button(
      v-if="$can('EXPORT_ORGS')"
      :disabled="listIsLoading || (listTotal > 10000)"
      :query="listQuery"
      :action="exportAction"
      fileNamePrefix="organizations"
      :total="listTotal"
    )

  template(#list)
    v-data-table(
      loading-text=""
      :headers="headers"
      :items="listItems"
      must-sort
      :items-per-page.sync="listLimit"
      :sort-by.sync="listOrderBy"
      :sort-desc.sync="listOrderType"
      :loading="listIsLoading"
      no-data-text="Нет организаций по данным фильтрам"
      hide-default-footer
    )

      template(#progress)
        Spinner

      template(#header)
        div.hidden-xs-only
          PerPagePicker(
            v-model="listLimit"
            data-table
          )

      template(#item.name="{ item }")
        div.font-weight-medium.py-4 {{item.name}}
      template(#item.address="{ item }")
        div.py-4 {{item.address}}

  template(#footer)
    v-pagination(
      v-show="listShowPagination"
      v-model="listCurrentPage"
      :length="listTotalPages"
      total-visible="10"
    )
</template>

<script>
import ListPageLayout from '@/components/layouts/ListPageLayout.vue';
import Spinner from '@/components/Spinner.vue';
import ExportButton from '@/components/ExportButton';
import PerPagePicker from '@/components/controls/PerPagePicker';

import resourceListFactory from '@/utils/mixins/resourceListFactory';

export default {
  components: {
    ListPageLayout,
    ExportButton,
    Spinner,
    PerPagePicker,
  },

  extends: resourceListFactory('ORGANIZATIONS', [], 'организаций'),

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id', width: '150px' },
        { text: 'Название', value: 'name' },
        { text: 'Телефон', value: 'phone', sortable: false, width: '200px' },
        // { text: 'ИНН', value: 'inn', width: '200px' }, For future releases
        { text: 'Адрес', value: 'address', width: '400px' },
      ],
    };
  },

  computed: {
    exportAction() {
      return 'ORGANIZATIONS/export';
    },
  },
};
</script>
